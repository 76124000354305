<template>
  <div>
    <div v-if="listInfo.length > 0">
      <ol>
        <li class="py-2" v-for="(item,i) in listInfo" v-bind:key="i">
          <p v-html="item.information" />
        </li>
      </ol>
    </div>
    <div v-else>
      Belum ada Informasi terbaru
    </div>
  </div>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import moment from 'moment'
import { getDashboardInfo } from '@/api/admin'

export default {
  name: 'dashboardInfo',
  data () {
    return {
        isLoading: false,
        currrole: [],
        listInfo: [],
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      var sdate = moment(value).add(7, 'hours').format('DD MMM YYYY')
      return sdate
    },
    formatDatetime: function (value) {
      if (!value) return ''
      var sdate = moment(value).add(7, 'hours').format('DD/MM/YYYY HH:mm')
      return sdate
    }
  },
  created () {
    const vm = this
    // console.log('created getInfoData')
    vm.getInfoData()
  },
  destroyed () {
  },
  mounted () {
    const vm = this
    // console.log('mount getInfoData')
    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)
  },
  methods: {
    getInfoData (){
      const vm = this

      vm.isLoading = true
      getDashboardInfo().then(response => {
        var data = response.data
        vm.listInfo = data
        // console.log('getDashboardInfo', data)

        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
  }
}

</script>

<style>
.v-input--selection-controls {
    margin: 0;
    padding: 0; 
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>
